<div class="card p-30 m-l-20 m-r-20 m-b-20">
  <div class="dateForm">

    <mat-form-field appearance="fill">
      <mat-label>{{'stats.Enter_a_date_range'|translate}}</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error
        *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'stats.Invalid_start_date'|translate}}</mat-error>
      <mat-error
        *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'stats.Invalid_end_date'|translate}}</mat-error>
    </mat-form-field>
    <button (click)="calculateStats()" mat-icon-button color="primary" style="margin-bottom: 8px !important"
      aria-label="Reload Report">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>

  <div>

    <mat-spinner *ngIf="statsLoading"></mat-spinner>
    <div *ngIf="!statsLoading" class="stats">
      <mat-card class="item">
        <mat-card-header>
          <mat-card-title>Subtotal</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <h1 class="sansSerif text-center">{{dailySubtotal | currency}}</h1>
        </mat-card-content>
      </mat-card>

      <mat-card class="item ">
        <mat-card-header>
          <mat-card-title>{{'stats.Tax'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <h1 class="sansSerif text-center">{{tax | currency}}</h1>
        </mat-card-content>
      </mat-card>

      <mat-card class="item ">
        <mat-card-header>
          <mat-card-title>{{'stats.Total_Tips'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <h1 class="sansSerif text-center">{{totalTip | currency}}</h1>
        </mat-card-content>
      </mat-card>


      <mat-card class="item ">
        <mat-card-header>
          <mat-card-title>{{'stats.Total_fee'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <h1 class="sansSerif text-center">{{totalFee | currency}}</h1>
        </mat-card-content>
      </mat-card>


      <mat-card class="item ">
        <mat-card-header>
          <mat-card-title>{{'stats.Total'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <h1 class="sansSerif text-center">{{dailyTotal | currency}}</h1>
        </mat-card-content>
      </mat-card>

      <mat-card class="item ">
        <mat-card-header>
          <mat-card-title>{{'stats.Restaurant_Total'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <h1 class="sansSerif text-center">{{restaurantTotal | currency}}</h1>
        </mat-card-content>
      </mat-card>

      <mat-card class="item ">
        <mat-card-header>
          <mat-card-title>{{'stats.Order_Count'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <h1 class="sansSerif text-center">{{ totalOrder | number }}</h1>
        </mat-card-content>
      </mat-card>


      <mat-card class="item ">
        <mat-card-header>
          <mat-card-title>{{'stats.Cancels'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <h1 class="sansSerif text-center">{{cancelCount}}</h1>
        </mat-card-content>
      </mat-card>



      <mat-card class="item ">
        <mat-card-header>
          <mat-card-title>{{'stats.Lifetime_Orders'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content *ngIf="stats">
          <h1 class="sansSerif text-center">{{ stats.lifetime }}</h1>
        </mat-card-content>

      </mat-card>

      <mat-card class="item ">
        <mat-card-header>
          <mat-card-title>Pickup Orders</mat-card-title>
        </mat-card-header>

        <mat-card-content *ngIf="stats">
          <h1 class="sansSerif text-center">{{ pickupCount }}</h1>
        </mat-card-content>

      </mat-card>

      <mat-card class="item ">
        <mat-card-header>
          <mat-card-title>Delivery Orders</mat-card-title>
        </mat-card-header>

        <mat-card-content *ngIf="stats">
          <h1 class="sansSerif text-center">{{ deliveryCount }}</h1>
        </mat-card-content>

      </mat-card>

    </div>
    <div class="print-button">
      <button mat-flat-button (click)="printReport()" class="big-button" color="accent">Print Report</button>
    </div>
  </div>
</div>